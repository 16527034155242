<template>
  <component :is="layout">
    <template v-slot:title>Account Settings</template>
    <div class="p-0 p-md-3 bg-gray-light-4 overflow-auto h-100">
      <div class="row">
        <!-- Cards -->
        <div class="col-12 col-md-8 offset-md-2">
          <AppCard class="mb-4">
            <div class="p-3">
              <h4 class="h5 m-0 mb-3">Personal Information</h4>
              <!-- <div class="profile-picture">
                <span>HK</span> <span>Change Profile Picture</span>
              </div> -->
              <AppRowInputContainer label="First Name">
                <AppBaseInput value="Hey" />
              </AppRowInputContainer>
              <AppRowInputContainer label="Last Name">
                <AppBaseInput value="" />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company">
                <label class="col-form-label py-0">Antelop Comp.</label>
              </AppRowInputContainer>
              <AppRowInputContainer label="Company Address">
                <label class="col-form-label py-0"
                  >3462 Godfrey Road, New York City, New York
                </label>
              </AppRowInputContainer>
              <AppRowInputContainer label="Email Address">
                <label class="col-form-label py-0"
                  >khudsin@antelopbuilders.com
                </label>
                <!-- <AppBaseLink
                  :to="{ path: '/change-email' }"
                  class="btn btn-link text-primary py-0 px-1"
                  >Edit</AppBaseLink
                > -->
              </AppRowInputContainer>
              <AppRowInputContainer label="Phone Number">
                <label class="col-form-label py-0">202 555 0195</label>
                <!-- <AppBaseLink
                  :to="{ path: '/change-number' }"
                  class="btn btn-link text-primary py-0 px-1"
                  >Edit</AppBaseLink
                > -->
              </AppRowInputContainer>
              <AppRowInputContainer label="Password">
                <label class="col-form-label py-0">*********</label>
                <AppBaseLink
                  :to="{ path: '/change-password' }"
                  class="btn btn-link text-primary py-0 px-1"
                  >Change Password</AppBaseLink
                >
              </AppRowInputContainer>

              <h4 class="h5 m-0 mb-3">Company Information</h4>
              <AppRowInputContainer label="Company">
                <AppBaseInput value="" />
              </AppRowInputContainer>
              <AppRowInputContainer label="Company Address">
                <AppBaseInput value="" />
              </AppRowInputContainer>
            </div>
          </AppCard>
          <AppBtn
            class="mr-0 ml-auto d-block"
            :loading="false"
            @click="() => {}"
          >
            Save Changes
          </AppBtn>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppRowInputContainer from '@/shared/elements/AppRowInputContainer.vue';
import AppBaseLink from '@/shared/elements/AppBaseLink.vue';
import AppBaseInput from '@/shared/elements/AppBaseInput.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';

export default {
  components: {
    AppCard,
    AppBaseLink,
    AppBtn,
    AppBaseInput,
    AppRowInputContainer,
    LayoutSuper: () =>
      import('@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin'),
    LayoutVendor: () =>
      import('@/components/Partial/Layouts/LayoutVendor/LayoutVendor'),
    LayoutBuyer: () =>
      import('@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer'),
  },

  computed: {
    layout() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'LayoutSuper';
        case PersonaConstants.vendor.value:
          return 'LayoutVendor';
        case PersonaConstants.buyer.value:
        default:
          return 'LayoutBuyer';
      }
    },
  },

  mounted() {},

  methods: {
    handleSave() {},
  },
};
</script>
